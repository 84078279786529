import { writable } from 'svelte/store';
import { browser } from '$app/environment';
import keycloak from '$lib/keycloak';

interface AuthState {
	isAuthenticated: boolean;
	username: string | null;
	isAdmin: boolean;
	isInitialized: boolean;
}

function createAuthStore() {
	const { subscribe, set, update } = writable<AuthState>({
		isAuthenticated: false,
		username: null,
		isAdmin: false,
		isInitialized: false
	});

	return {
		subscribe,
		setAuth: (state: Omit<AuthState, 'isInitialized'>) => update(current => ({ ...state, isInitialized: true })),
		login: () => update(state => ({ ...state, isAuthenticated: true })),
		logout: () => set({ isAuthenticated: false, username: null, isAdmin: false, isInitialized: true }),
		init: () => {
			if (browser && keycloak) {
				const isAuthenticated = keycloak.authenticated || false;
				const username = keycloak.tokenParsed?.preferred_username || null;
				const isAdmin = checkAdminRole();
				set({ isAuthenticated, username, isAdmin, isInitialized: true });
			}
		}
	};
}

function checkAdminRole(): boolean {
	const roles = keycloak?.tokenParsed?.realm_access?.roles || [];
	return roles.includes('admin');
}

export const authStore = createAuthStore();